<template>
    <div class="fixed-top d-flex align-items-center justify-content-center" style="bottom:0; overflow-y: auto">
    <div class="col-md-6 col-12">
    <div class="card">
        <div class="card-header border-2">
            <b-img height="60" :src="require('@/assets/images/logo/mutumed.png')"/>
            <h4 class="card-title">PT. MUTUMED PRIMA SERVICES</h4>
        </div>
        <div class="text-center text-white" :class="getStatusColor(st)">
            <h5 class="mt-1 text-white">MPS-4023</h5>
        </div>
            <div class="card-body">
                <div class="row">
                <div class="col-12">
                    <label class="form-label"></label>
                </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="mb-1">
                        <label for="date" class="form-label">Tanggal dilakukan Pengujian dan Kalibrasi: </label>
                        <input type="text" class="form-control" id="date" v-model="start_date" readonly="readonly"/>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-1">
                        <label for="expireDate" class="form-label"> {{ status }} s/d:</label>
                        <input type="text" class="form-control" id="expireDate" v-model="end_date" readonly="readonly"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="mb-1">
                        <label for="instance" class="form-label">Instansi:</label>
                        <input type="text" class="form-control" id="instance" v-model="instansi" readonly="readonly"/>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-1">
                        <label for="deviceName" class="form-label">Nama Alat:</label>
                        <input type="text" class="form-control" id="deviceName" v-model="product" readonly="readonly"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="mb-1">
                        <label for="brand" class="form-label">Merek:</label>
                        <input type="text" class="form-control" id="brand" v-model="merk" readonly="readonly"/>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-1">
                        <label for="type" class="form-label">Tipe:</label>
                        <input type="text" class="form-control" id="type" v-model="type" readonly="readonly"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="mb-1">
                        <label for="seriesNumber" class="form-label">No. Seri:</label>
                        <input type="text" class="form-control" id="seriesNumber" v-model="code" readonly="readonly"/>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-1">
                        <label for="room" class="form-label">Keterangan:</label>
                        <input type="text" class="form-control" id="room" v-model="desc" readonly="readonly"/>
                        </div>
                    </div>
                </div>
        </div>
    <div class="card-footer text-white" :class="getStatusColor(st)">
        DINYATAKAN {{ status }}
    </div>
</div>
</div>
</div>
</template>

<script>
import {BCard, BFormInput, BRow, BCol, BFormGroup, BImg} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moduleApi from '@/store/module'

export default {
    data() {
    return {
            start_date: '',
            end_date: '',
            instansi: '',
            product: '',
            merk: '',
            type: '',
            code: '',
            desc: '',
            st: '',
            status: '',
        }
    },
    components: {
        BCard,
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BImg
    },
    methods: {
        fetchDataCode() { 
        moduleApi.fetchDataCode(this.$route.params.id)
            .then(response => {
                
                this.rows  = response.data.data
                this.start_date = response.data.data.created_at
                const dt = this.start_date.split("-");
                const year = parseInt(dt[2]) + parseInt(1)
                this.end_date = `${dt[0]}-${dt[1]}-${year}`

                this.instansi = response.data.data.name
                this.product = response.data.data.name
                this.merk = response.data.data.merk
                this.type = response.data.data.types
                this.code = response.data.data.code
                this.desc = response.data.data.note
                this.st = response.data.data.status
                this.status = this.getStatusInfo(response.data.data.status)
                console.log(response.data.data)
            })
            .catch(error => {
            this.$refs.loginForm.setErrors(error.response.data.error)
            })
        },
        getStatusColor (status) {
            if (status === 1)   return 'bg-success'
            if (status === 2) return 'bg-warning'
            return 'bg-danger'
            },
        getStatusInfo (status) {
            if (status === 1)  return 'LAIK PAKAI'
            if (status === 2)  return 'LAIK PAKAI'
            return 'TIDAK LAIK PAKAI'
        },
    },
    created() {
        this.fetchDataCode() 
    },
    setup(props) {
        const { t } = useI18nUtils()

        return {
        // i18n 
        t,
        }
    },
}
</script>

<style lang="scss" >
// @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
